import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../../environments/environment';



@Injectable()
export class AppInsightsService {
  appInsights: ApplicationInsights;
  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true, // option to log all route changes
        autoTrackPageVisitTime: true
      }
    });
    this.appInsights.loadAppInsights();
    const telemetryInitializer = (envelope) => {
      envelope.tags["ai.cloud.role"] = "VC2 Service User App";
      envelope.data.inCell = this.setInCell();
      envelope.data.establishmentId = this.setEstId();

    }
    this.appInsights.addTelemetryInitializer(telemetryInitializer);
  }

  setEstId(): any {
    return localStorage.getItem('estId')?localStorage.getItem('estId'):"0";
  }

  setInCell(): any {
    return localStorage.getItem('inCell') && localStorage.getItem('inCell') === 'true' ? true : false;
  }

  logPageView(name?: string, url?: string) { // option to call manually
   const customDimentions: any = this.setCustomDimentions({});
    this.appInsights.trackPageView({
      name: name,
      uri: url,
      properties: customDimentions
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if(!properties){
      properties = {};
    }
    this.setCustomDimentions(properties);
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if(!properties){
      properties = {};
    }
    this.setCustomDimentions(properties);
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if(!properties){
      properties = {};
    }
    this.setCustomDimentions(properties);
    this.appInsights.trackTrace({ message: message }, properties);
  }

  setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string): void {
    this.appInsights.setAuthenticatedUserContext(authenticatedUserId, "" + accountId);
  }

  clearAuthenticatedUserContext() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  setCustomDimentions(properties: { [key: string]: any; }) {
    properties["inCell"] = this.setInCell();
  }
}