import { Component, OnInit } from '@angular/core';
import { AppInsightsService } from '../../core/services/appinsight.service';
import { TogetherModeModalService } from '../../shared/components/together-mode-modal/together-mode-modal.service';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'vc-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss']
})
export class SignOutComponent implements OnInit {

  constructor(
    private readonly togetherModeModalService: TogetherModeModalService,
    private readonly authenticationService: AuthenticationService,
    private readonly appInsightsService: AppInsightsService
  ) {
    this.togetherModeModalService.setTogetherModeFalse();
    this.authenticationService.signout().subscribe(rsp => {
      this.appInsightsService.clearAuthenticatedUserContext();
    });
  }

  ngOnInit() {

  }

}
