export const environment = {
  production: false,
  api: 'https://devservices.sequation.net',
  enableCtrl: true,
  moodleURL: 'https://dev-teacher.hmpps.college/',
  azureBlobStorage: 'https://sequationdevsa.blob.core.windows.net',
  appInsights: {
    instrumentationKey: '451221f0-7c79-4813-9ea3-a8b385b2fd5b'
  }
};
