import { ContentUnavailableComponent } from './shared/components/content-unavailable/content-unavailable.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from './core/guards/authenticated.guard';
import { SignOutComponent } from './authentication/sign-out/sign-out.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./authentication/sign-in/sign-in.module').then(mod => mod.SignInModule),
    data: { title: 'Log in to HMPPS College'}
  },
  {
    path: 'sign-out',
    component: SignOutComponent,
    data: {title: 'Log in to HMPPS College'}
  },
  {
    path: '**',
    redirectTo: 'content-unavailable',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
